import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import { gtag } from 'gatsby-plugin-google-gtag';

// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
const features = [
  {
    name: "Date",
    description: "8th July 2023",
    //   icon: CloudArrowUpIcon,
  },
  {
    name: "Time",
    description: "Cocktail from 6pm to 6.30pm. Opening show & dinner from 7pm",
    //   icon: LockClosedIcon,
  },
  {
    name: "Address",
    description:
      "Raffles Town Club, 1 Plymouth Ave, Singapore 297753, Ballroom 2 Level 1",
    //   icon: ServerIcon,
  },
];

const faqs = [
  {
    id: 1,
    question: "What's the dress code?",
    answer:
      "Dress Code Theme: 'Lady in Red' for ladies and 'Black Glam' for men. The dress code theme has been carefully selected to add an element of fun and elegance to the evening.",
  },
  {
    id: 2,
    question: "What's time will the event end?",
    answer:
      "The event will end at around 11.00pm. We encourage you to stay till the end of the event for Guess The Winner Contest result to win attractive prizes",
  },
  {
    id: 3,
    question: "What are the programmes or activities?",
    answer:
      "Details of the programmes are availabel from our Programme E-booklet. You may download a copy from the links located at the next section below",
  },
  {
    id: 4,
    question: "How can I make a donation?",
    answer:
      "You may make a donation to Singapore Women's Association via Paynow. QR code will be made available at your respective table to make a donation via your banking or payment app",
  },
  {
    id: 5,
    question: "Any complimentary parking coupons?",
    answer:
      "Complimentary parking coupons are available at the registration table, first come first serve basis.",
  },
];


const NoticePage = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>

      {/* <Layout> */}
      <SEO
        title="Event Details - 46th SWA Annual Charity Gala 2023"
        description="Singapore Women's Association 46th Annual Charity Gala featuring Miss Singapore Pageant International 2023"
        image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638588570/Logo/qiuk4zzjcgmidemdqavw.webp"
        imageAlt="Miss Singapore International Pageant | Application"
      />
      <div className="px-6 py-3 bg-white sm:py-4 lg:px-8 ">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-4xl font-bold tracking-tight text-swa-3 sm:text-6xl">
            Event Information
          </h2>
          {/* <p className="mt-6 text-2xl leading-8 text-gray-600 ">
            46th SWA Annual Charity Gala 2023
          </p> */}
        </div>
        <div className="flow-root px-6 py-6 sm:py-8 ">
          {/* <img
          src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1686461188/charityDinnerPage/xgzlqwk4qpeo6cdyfxet.webp"
          alt="MSPI"
            width={500}
            height={300}
          className="object-cover mx-auto rounded-md shadow-2xl ring-1 ring-gray-900/10"
        /> */}
          <div className="flow-root">
            <div className="">
              <img
                src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1686373793/charityDinnerPage/qi1studzczbadspgjdbe.webp"
                alt="SWA Charity Gala"
                width={1000}
                height={700}
                className="mx-auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pb-2 overflow-hidden bg-white sm:py-4">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
              <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-lg">
                {/* <h2 className="text-base font-semibold leading-7 text-swa-3">Deploy faster</h2> */}
                {/* <p className="mt-2 text-3xl font-bold tracking-tight text-swa-3 sm:text-4xl">
                  Where
                </p> */}
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  We are delighted to extend our warmest greetings to you as a
                  valued guest of the 46th SWA Annual Charity Gala. We would
                  like to provide you with some important details to ensure that
                  you have a memorable and enjoyable experience at the event.
                  Please take note of the following information:
                </p>
                <dl className="max-w-xl mt-10 space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map(feature => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline ">
                        {/* <feature.icon className="absolute w-5 h-5 text-indigo-600 left-1 top-1" aria-hidden="true" /> */}
                        {feature.name}
                        <br />
                      </dt>{" "}
                      <dd className="inline font-semibold text-gray-900">
                        {feature.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>

            <div className="sm:px-6 lg:px-0 sm:my-12">
              <div className="relative overflow-hidden bg-indigo-500 isolate sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none sm:mt-2">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="max-w-2xl mx-auto sm:mx-0 sm:max-w-none">
                  {/* <img
                  src="https://tailwindui.com/img/component-images/project-app-screenshot.png"
                  alt="Product screenshot"
                  width={2432}
                  height={1442}
                  className="-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                /> */}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.026473292348!2d103.8250124!3d1.3218191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19f92b228ad3%3A0x797941a12608d9cd!2sRaffles%20Town%20Club!5e0!3m2!1sen!2ssg!4v1686455534734!5m2!1sen!2ssg"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="mx-auto"
                  ></iframe>
                </div>
                <div
                  className="absolute inset-0 pointer-events-none ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-2xl mx-auto my-6 text-center sm:my-12">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-swa-3">
          Ballroom Floor/Seating Plan
        </h2>
        <div className="my-5">
          <img
            src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1687224745/notice/lowresfloorplan.webp"
            alt="SWA Charity Gala Ballroom Floor Plan"
            width={700}
            height={500}
            className="mx-auto"
          />
        </div>

        <a
          href="https://res.cloudinary.com/dkm14ge3g/image/upload/v1687224611/notice/highresfloorplan.png"
          target="_blank"
          rel="noopener noreferrer"
          className="rounded-md bg-swa-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-swa-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-swa-3"
        >
          Download larger image
        </a>
      </div>

      <div className="bg-white">
        <div className="px-6 py-6 mt-6 bg-gray-200 sm:px-6 sm:py-16 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-swa-3 sm:text-4xl">
              Event Programme E-Book
            </h2>
            <p className="max-w-xl mx-auto mt-6 text-lg leading-8 text-gray-600">
              Check out the event programmes, learn more about Singapore Women's
              Association, view photos of the finalists for Miss Singapore
              Pageant International 2023 and our sponsors.
            </p>

            <div className="flex flex-col items-center justify-center mt-10 gap-x-6">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2FMobile%20SWA%20E-Book8July_linked.pdf?alt=media&token=078af28d-8719-4223-aabc-b69e412f49d1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                View from your mobile phone?
               
                 <br/>Download this version
                <span aria-hidden="true">→</span>
                <br/><span className="font-light text-gray-500">file size: 17mb</span>
              </a>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2FMobile%20SWA%20E-Book8July_linked.pdf?alt=media&token=078af28d-8719-4223-aabc-b69e412f49d1"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-swa-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-swa-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-swa-3"
              >
                Download mobile version
              </a>

            </div>

            <div className="flex flex-col items-center justify-center mt-10 gap-x-6">
              <a
                href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2FProgramme%20e-booklet_8July_linked.pdf?alt=media&token=249115b1-984d-4b6e-a997-63c697aa87d1"
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Desktop/tablet version <span aria-hidden="true">→</span>
              <br/><span className="font-light text-gray-500">file size: 17mb</span>
              </a>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/swa-admin-23d1e.appspot.com/o/download%2FProgramme%20e-booklet_8July_linked.pdf?alt=media&token=249115b1-984d-4b6e-a997-63c697aa87d1"
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-md bg-swa-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-swa-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-swa-3"
              >
                Download tablet version
              </a>
            </div>


          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="px-6 py-8 mx-auto max-w-7xl sm:py-18 lg:px-4">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-swa-3">
              Frequently asked questions
            </h2>
            {/* <p className="mt-6 text-base leading-7 text-gray-600">
            Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
            <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
              sending us an email
            </a>{' '}
            and we’ll get back to you as soon as we can.
          </p> */}
          </div>
          <div className="mt-20">
            <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
              {faqs.map(faq => (
                <div key={faq.id}>
                  <dt className="text-base font-semibold leading-7 text-swa-3">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="px-6 py-12 bg-white sm:py-16 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          {/* <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Support center</h2> */}
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We are excited about the lineup of activities and entertainment
            planned for the evening. Your presence will contribute to the
            success of this event and support our noble cause.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Please be informed that photography and video recording will be
            taking place throughout the event. These images and footage may be
            used for publicity purposes, including Singapore Women's
            Association's website and social media platforms.
          </p>

          <p className="mt-6 text-lg leading-8 text-gray-600">
            If you have any further questions or require additional information,
            please do not hesitate to contact us.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We look forward to welcoming you to the 46th SWA Annual Charity Gala
            and sharing an unforgettable evening with you. Thank you for your
            participation.
          </p>

          {/* <h3 className="mt-6 leading-8 text-swa-3">
            Singapore Women's Association
          </h3> */}
        </div>
      </div>

      <div className="bg-white">
        <div className="px-6 py-12 sm:px-6 sm:py-16 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              About Singapore Women's Association
            </h2>
            <p className="max-w-xl mx-auto mt-6 text-lg leading-8 text-gray-600">
              Visit our website to learn more about what we do.
            </p>
            <div className="flex items-center justify-center mt-10 gap-x-6">
              <a
                href="/"
                className="rounded-md bg-swa-3 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-swa-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-swa-3"
              >
                Visit SWA website
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* </Layout> */}
    </>
  );
};

export default NoticePage;
